<template>
  <div class="new-purchase">
    <div class="modal-header">
      <h4 class="header-text">Создание закупки</h4>
      <span style="margin-right: 10px; font-size: 17px"
        ><b-icon icon="x-lg" type="submit" @click="$emit('showModal')"></b-icon
      ></span>
    </div>
    <div class="button_select">
      <b-form-select
        v-model="form.contractor"
        class="modal__select"
        style="width: 400px"
        :options="listContractor"
        text-field="name"
        value-field="id"
      />
      <div class="invoice-upload" v-if="form.documents.length === 0">
        <input
          type="file"
          id="fileInput"
          ref="fileInput"
          class="invoice"
          :style="{ right: invoiceStyle }"
          accept=".pdf, .doc, .docx"
          :disabled="disabledShowFile"
          @change="previewFileAll"
        />
        <svg
          width="18"
          height="18"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M20 12.5V6.8C20 5.11984 20 4.27976 19.673 3.63803C19.3854 3.07354 18.9265 2.6146 18.362 2.32698C17.7202 2 16.8802 2 15.2 2H8.8C7.11984 2 6.27976 2 5.63803 2.32698C5.07354 2.6146 4.6146 3.07354 4.32698 3.63803C4 4.27976 4 5.11984 4 6.8V17.2C4 18.8802 4 19.7202 4.32698 20.362C4.6146 20.9265 5.07354 21.3854 5.63803 21.673C6.27976 22 7.1198 22 8.79986 22H12.5M14 11H8M10 15H8M16 7H8M15 19L18 22M18 22L21 19M18 22V16"
            stroke="#3F8AE0"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        Загрузить счет*
      </div>
      <button
        class="invoice-uploaded"
        title="Удалить загруженный документ"
        @click="deleteFile"
        v-else
      >
        {{ file_name }}
        <span style="font-size: 17px; background-color: white"
          ><b-icon icon="file-earmark-excel"></b-icon
        ></span>
      </button>
    </div>

    <div class="choices-block flex">
      <div class="choices">
        <button
          :class="form.type === 'Покупка' ? 'btn_form_active' : 'btn_form'"
          style="margin-right: 10px"
          @click="changeType('Покупка')"
        >
          Товар
        </button>
        <button
          :class="form.type === 'Услуга' ? 'btn_form_active' : 'btn_form'"
          @click="changeType('Услуга')"
        >
          Услуга
        </button>
      </div>
      <div class="flex">
        <label for="check-button" class="check-nds">Ставка НДС*</label>
        <b-form-checkbox
          size="lg"
          style="margin-right: 10px"
          v-model="form.vatRate"
          id="check-button"
          switch
        >
        </b-form-checkbox>
      </div>
    </div>
    <div class="modal-body" v-if="form.type === 'Покупка'">
      <button
        :class="productIndex === index ? 'btn-quantity-active' : 'btn-quantity'"
        v-for="(product, index) in products"
        :key="index"
        @click="productIndex = index"
      >
        №{{ index + 1 }}
      </button>
      <button class="delete-item" v-if="productIndex > 0" @click="deleteItem()">
        Удалить товар
      </button>
      <div class="modal-columns">
        <div class="modal-column" style="margin-right: 40px">
          <label for="category" class="label_inputs">Категория товара*</label><br />
          <b-form-select
            id="category"
            v-model="products[productIndex].category"
            class="modal__select"
            :options="listCategory"
            text-field="category"
            @change="filterFields('product-category')"
            value-field="id"
          />

          <label for="description" class="label_inputs" style="margin-bottom: 17px"
            >Описание</label
          >
          <b-form-textarea
            id="description"
            v-model="products[productIndex].description"
            placeholder="Введите описание характеристик"
            class="modal__input"
            style="line-height: 20px"
            rows="6"
          />

          <label for="manufacturer" class="label_inputs">Производитель*</label><br />
          <b-form-input
            id="manufacturer"
            v-model="products[productIndex].manufacturer"
            placeholder="Введите производителя"
            class="modal__input"
          />

          <label for="condition" class="label_inputs">Состояние*</label><br />
          <b-form-select
            id="condition"
            v-model="products[productIndex].condition"
            class="modal__select"
            :options="products[productIndex].listConditions"
            text-field="ru"
            value-field="name"
          />
        </div>

        <div class="modal-column">
          <label for="product" class="label_inputs">Наименование товара*</label><br />
          <b-form-select
            id="product"
            v-model="products[productIndex].field_id"
            class="modal__select"
            :options="products[productIndex].listProdNames"
            @change="filterFields('product-name')"
            text-field="name"
            value-field="id"
          />
          <label for="sum" class="label_inputs">Стоимость*</label><br />
          <b-form-input
            id="sum"
            v-model="products[productIndex].sum"
            type="text"
            placeholder="Введите полную сумму"
            class="modal__input"
            :formatter="numberFormat"
          />

          <label for="quantity" class="label_inputs">Количество*</label><br />
          <div class="modal__inputs-quantity">
            <b-form-input
              id="quantity"
              v-model="products[productIndex].quantity"
              type="number"
              placeholder="Введите количество"
              class="modal__input-quantity"
            />
            <b-form-select
              id="product"
              v-model="products[productIndex].unit"
              class="modal__select-quantity"
              :options="products[productIndex].listProdUnits"
              text-field="ru"
              value-field="name"
              style="margin-left: 20px"
            />
          </div>
          <label for="country" class="label_inputs">Страна производителя*</label><br />
          <b-form-select
            id="country"
            v-model="products[productIndex].country"
            required
            class="modal__select"
            :options="listCountries"
            text-field="ru"
            value-field="name"
          />
          <label for="appointment" class="label_inputs">Назначение*</label><br />
          <b-form-select
            id="appointment"
            v-model="products[productIndex].appointment"
            class="modal__select"
            :options="listTransport"
            text-field="name"
            value-field="id"
          />
        </div>
      </div>
    </div>

    <div class="modal-body" v-else>
      <button
        :class="serviceIndex === index ? 'btn-quantity-active' : 'btn-quantity'"
        v-for="(service, index) in services"
        :key="index"
        @click="serviceIndex = index"
      >
        №{{ index + 1 }}
      </button>
      <button class="delete-item" v-if="serviceIndex > 0" @click="deleteItem()">
        Удалить услугу
      </button>
      <div class="modal-columns">
        <div class="modal-column" style="margin-right: 40px">
          <label for="category" class="label_inputs">Категория услуги*</label><br />
          <b-form-select
            id="category"
            v-model="services[serviceIndex].category"
            class="modal__select"
            :options="listCategory"
            @change="filterFields('service-category')"
            text-field="category"
            value-field="id"
          /><br />

          <label for="description" class="label_inputs" style="margin-bottom: 20px"
            >Описание</label
          >
          <b-form-textarea
            id="description"
            v-model="services[serviceIndex].description"
            placeholder="Введите описание характеристик"
            class="modal__input"
            style="line-height: 19px"
            rows="11"
          />

          <label for="appointment" class="label_inputs">Назначение*</label><br />
          <b-form-select
            id="appointment"
            v-model="services[serviceIndex].appointment"
            class="modal__select"
            :options="listTransport"
            text-field="name"
            value-field="id"
          />
        </div>

        <div class="modal-column">
          <label for="service" class="label_inputs">Наименование услуги*</label><br />
          <b-form-select
            id="service"
            v-model="services[serviceIndex].field_id"
            class="modal__select"
            :options="services[serviceIndex].listSerNames"
            @change="filterFields('service-name')"
            text-field="name"
            value-field="id"
          />
          <label for="sum" class="label_inputs">Ожидаемая сумма расхода*</label><br />
          <b-form-input
            id="sum"
            v-model="services[serviceIndex].sum"
            type="text"
            placeholder="Введите полную сумму"
            class="modal__input"
            :formatter="numberFormat"
          />

          <label for="hour" class="label_inputs">Срок реализации*</label><br />
          <div class="modal__inputs-quantity">
            <b-form-input
              id="sum"
              v-model="services[serviceIndex].hour"
              type="number"
              placeholder="Часы"
              class="modal__input-quantity"
            />
            <b-form-input
              id="sum"
              v-model="services[serviceIndex].day"
              type="number"
              placeholder="Дни"
              style="margin-left: 20px"
              class="modal__input-quantity"
            />
          </div>
          <label for="responsible" class="label_inputs">Ответственный*</label><br />
          <b-form-select
            id="responsible"
            v-model="services[serviceIndex].responsible"
            class="modal__select"
            :options="listDrivers"
            text-field="name"
            value-field="id"
          />
          <label for="quantity" class="label_inputs">Количество*</label><br />
          <div class="modal__inputs-quantity">
            <b-form-input
              id="quantity"
              v-model="services[serviceIndex].quantity"
              type="number"
              placeholder="Введите количество"
              class="modal__input-quantity"
            />
            <b-form-select
              id="product"
              v-model="services[serviceIndex].unit"
              class="modal__select-quantity"
              :options="services[serviceIndex].listSerUnits"
              text-field="ru"
              value-field="name"
              style="margin-left: 20px"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <div class="add__product" @click="addItem">
        ⨁ Добавить {{ form.type === "Покупка" ? "товар" : "услугу" }}
      </div>
      <button
        class="header__btn right-item default-btn modal__button1"
        @click="$emit('closeModal')"
      >
        Отменить
      </button>
      <button
        type="submit"
        @click="save()"
        class="header__btn right-item default-btn modal__button2"
      >
        Сохранить
      </button>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: {
    invoiceStyle: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      listContractor: [
        { id: "", name: "Выбрать контрагент*" },
        // { id: "aqniet", name: "Aqniet Group" },
      ],
      disabledShowFile: false,
      isInputshow: true,
      form: {
        documents: [],
        contractor: "",
        type: "Покупка",
        vatRate: false,
      },
      checkedNds: false,
      productIndex: 0,
      listCountries: [
        { name: "", ru: "Выберите страну" },
        { name: "Казахстан", ru: "Казахстан" },
        { name: "Россия", ru: "Россия" },
        { name: "Германия", ru: "Германия" },
        { name: "Китай", ru: "Китай" },
      ],
      products: [
        {
          category: "",
          condition: "",
          manufacturer: "",
          field_id: "",
          unit: "",
          country: "",
          appointment: 0,
          description: "",
          sum: "",
          quantity: "",
          listProdNames: [{ id: "", name: "Выберите наименование" }],
          listProdUnits: [
            { name: "", ru: "Ед. измерения" },
            { name: "Литр", ru: "Литр" },
            { name: "Канистра", ru: "Канистра" },
          ],
          listConditions: [
            { name: "", ru: "Выберите состояние" },
            { name: "Новое", ru: "Новое" },
            { name: "Б/У", ru: "Б/У" },
          ],
        },
      ],

      serviceIndex: 0,
      listDays: [
        { name: "", ru: "Дни" },
        { name: "0", ru: "0" },
        { name: "1", ru: "1" },
        { name: "2", ru: "2" },
        { name: "3", ru: "3" },
        { name: "4", ru: "4" },
      ],
      services: [
        {
          category: "",
          appointment: 0,
          field_id: "",
          hour: "",
          day: "",
          responsible: "",
          unit: "",
          description: "",
          sum: "",
          quantity: "",
          listSerNames: [{ name: "", ru: "Выберите наименование" }],
          listSerUnits: [
            { name: "", ru: "Ед. измерения" },
            { name: "Услуга", ru: "Услуга" },
            { name: "Норма часа", ru: "Норма часа" },
          ],
        },
      ],
      file_name: "",
    };
  },
  computed: {
    ...mapState([
      "transportList",
      "driversList",
      "purchaseCategories",
      "typeFilter",
      "purchaseFields",
      "counterpartyList"
    ]),
    listTransport() {
      let transports = [];
      if (this.form.type === "Покупка") {
        transports.push({ id: 0, name: "На склад" });
      }
      if (this.transportList) {
        this.transportList.forEach((e) => {
          transports.push({ id: e.id, name: e.model?.name + "|" + e.number + "|" + e.body });
        });
        return transports;
      }
      return transports;
    },
    listDrivers() {
      if (this.driversList) {
        return [{ id: "", name: "Выберите" }, ...this.driversList.data];
      }
      return [{ id: "", name: "Выберите", disabled: true }];
    },
    listCategory() {
      if (this.purchaseCategories) {
        return [{ id: "", category: "Выберите категорию" }, ...this.purchaseCategories];
      }
      return [{ id: "", category: "Выберите категорию", disabled: true }];
    },
  },
  async created() {
    this.$store.commit("typeFilter", "Покупка");
    this.$store.dispatch("getTransportList");
    this.$store.dispatch("getDriversList");
    await this.$store.dispatch("getCounterpartyList");
    this.listContractor =  this.counterpartyList.data;
  },
  methods: {
    filterFields(field) {
      let product = this.products[this.productIndex];
      if (field === "product-category") {
        this.$store.dispatch("getPurchaseFields", product.category);
      } else if (field === "product-name") {
        this.purchaseFields.data.forEach((e) => {
          if (e.id === product.field_id) {
            if (e.condition) {
              product.listConditions = [{ name: e.condition, ru: e.condition }];
              product.condition = e.condition;
            } else {
              product.listConditions = [
                { name: "", ru: "Выберите состояние" },
                { name: "Новое", ru: "Новое" },
                { name: "Б/У", ru: "Б/У" },
              ];
            }
            if (e.unit) {
              product.unit = e.unit;
              product.listProdUnits = [{ name: e.unit, ru: e.unit }];
            } else {
              product.listProdUnits = [
                { name: "", ru: "Ед. измерения" },
                { name: "Литр", ru: "Литр" },
                { name: "Канистра", ru: "Канистра" },
              ];
            }
          }
        });
      }

      let service = this.services[this.serviceIndex];
      if (field === "service-category") {
        this.$store.dispatch("getPurchaseFields", service.category);
      } else if (field === "service-name") {
        this.purchaseFields.data.forEach((e) => {
          if (e.id === service.field_id) {
            if (e.unit) {
              service.unit = e.unit;
              service.listSerUnits = [{ name: e.unit, ru: e.unit }];
            } else {
              service.listSerUnits = [
                { name: "", ru: "Ед. измерения" },
                { name: "Услуга", ru: "Услуга" },
                { name: "Норма часа", ru: "Норма часа" },
              ];
            }
          }
        });
      }
    },
    changeType(type) {
      this.form.type = type;
      this.$store.commit("typeFilter", type);
    },
    async search() {
      await this.$store.dispatch("getPurchaseCategories", {
        type: this.typeFilter,
      });
    },
    addItem() {
      let arr = [];
      this.transportList.forEach((e) => {
        arr.push({ id: e.id, name: e.model?.name + "|" + e.number + "|" + e.body });
      });
      if (this.form.type === "Покупка") {
        if (this.products.length < 10) {
          let lenght = this.products.length;
          this.products.splice(lenght, 0, {
            category: "",
            condition: "",
            manufacturer: "",
            field_id: "",
            unit: "",
            country: "",
            appointment: 0,
            listProdNames: [{ id: "", name: "Выберите наименование" }],
            listProdUnits: [
              { name: "", ru: "Ед. измерения" },
              { name: "Литр", ru: "Литр" },
              { name: "Канистра", ru: "Канистра" },
            ],
            listConditions: [
              { name: "", ru: "Выберите состояние" },
              { name: "Новое", ru: "Новое" },
              { name: "Б/У", ru: "Б/У" },
            ],
          });
        }
      } else {
        if (this.services.length < 10) {
          let lenght = this.services.length;
          this.services.splice(lenght, 0, {
            category: "",
            appointment: 0,
            field_id: "",
            hour: "",
            day: "",
            responsible: "",
            unit: "",
            listSerNames: [{ name: "", ru: "Выберите наименование" }],
            listSerUnits: [
              { name: "", ru: "Ед. измерения" },
              { name: "Услуга", ru: "Услуга" },
              { name: "Норма часа", ru: "Норма часа" },
            ],
          });
        }
      }
    },
    deleteItem() {
      if (this.form.type === "Покупка") {
        this.products.splice(this.productIndex, 1);
        this.productIndex = 0;
      } else {
        this.services.splice(this.serviceIndex, 1);
        this.serviceIndex = 0;
      }
    },
    previewFileAll() {
      this.form.documents = this.$refs.fileInput.files;
      Object.values(this.form.documents).forEach((e, index) => {
        this.file_name = this.form.documents[index].name;
      });
      this.disabledShowFile = true;
    },
    deleteFile() {
      this.form.documents = [];
      this.disabledShowFile = false;
    },
    save() {
      const formData = new FormData();
      formData.set("contractor", this.form.contractor);
      formData.set("vatRate", this.form.vatRate);
      Object.values(this.form.documents).forEach((e, index) => {
        formData.set(`documents[${index}]`, this.form.documents[index]);
      });
      for (let i = 0; i < this.products.length; i++) {
        for (let key of Object.keys(this.products[i])) {
          if(key == "sum"){
           
            let formatSum = this.products[i][key].replaceAll(' ', '')
            formData.set(`products[${i}][${key}]`, formatSum);
            continue
          }

          formData.set(`products[${i}][${key}]`, this.products[i][key]);
        }
      }
      for (let i = 0; i < this.services.length; i++) {
        for (let key of Object.keys(this.services[i])) {
          formData.set(`services[${i}][${key}]`, this.services[i][key]);
        }
      }

      this.$api
        .post("/web/add-purchase", formData)
        .then(() => {
          this.$toast.success("Успешно!");
          this.$store.dispatch("getPurchaseList");
          this.$emit("showModal");
        })
        .catch((e) => {
          this.$store.commit("setToastData", {
            text: e.response.data.message,
            position: "error",
          });
          this.$toast.error(<toast-block />);
          if (
            e.message.split(" ").reverse()[0] === 500 ||
            e.message.split(" ").reverse()[0] === 401 
          ) {
            this.$router.push(`/error/${e.message.split(" ").reverse()[0]}`);
          }else if(e.message.split(" ").reverse()[0] === 400){
            this.$router.push(`/error/${e}`);
          }
        });
    },
    numberFormat(value) {
      if (value.length > 0) {
        value = value.replaceAll(" ", "");
        value = value.match(/.{1,3}/g) ?? [];
        value = value.join("");
        let val = value.split(".");
        if (val[1]) {
          let v = val[0].match(/.{1,3}/g) ?? [];
          let v1 = val[1];
          value = v.join(" ");
          value = value + "." + v1;

          return value;
        } else {
          value = value.match(/.{1,3}/g) ?? [];
          value = value.join(" ");
          return value;
        }
      }
    },
  },
  watch: {
    async typeFilter() {
      await this.search();
    },
    async purchaseFields() {
      if (this.form.type === "Покупка") {
        this.products[this.productIndex].listProdNames = [
          { id: "", name: "Выберите наименование" },
        ];
        this.purchaseFields.data.forEach((e) => {
          this.products[this.productIndex].listProdNames.push({ name: e.name, id: e.id });
        });
      } else {
        this.services[this.serviceIndex].listSerNames = [{ id: "", name: "Выберите наименование" }];
        this.purchaseFields.data.forEach((e) => {
          this.services[this.serviceIndex].listSerNames.push({ name: e.name, id: e.id });
        });
      }
    },
  },
};
</script>

<style>
.modal-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  border-bottom: none;
}
.header-text {
  margin-left: 10px;
  margin-top: 10px;
}
.button_select {
  width: 100%;
  margin-bottom: 10px;
  margin-left: 1rem;
  display: flex;
}
.invoice-upload {
  background-color: #fff;
  border-radius: 8px;
  border: 1px solid #0366d6;
  margin-left: 40px;
  width: 400px;
  color: #0366d6;
  text-align: center;
  line-height: 35px;
}
.invoice-uploaded {
  background-color: #fff;
  border-radius: 8px;
  border: 1px solid #777777;
  margin-left: 40px;
  width: 400px;
  color: #777777;
  text-align: center;
  line-height: 35px;
}
.invoice {
  position: absolute;
  right: 10px;
  width: 400px;
  height: 30px;
  z-index: 20;
  opacity: 0;
}
.check-nds {
  font-weight: 400;
  margin-top: 5px;
  margin-right: 10px;
}
.custom-control-input:checked ~ .custom-control-label::before {
  background-color: #1bb55c;
}
.custom-control-input:checked ~ .custom-control-label::before {
  border-color: #1bb55c;
}
.btn_form_active {
  background: #ffffff;
  color: #1e98ff;
}
.btn_form {
  background: #ffffff;
}
.choices {
  margin-left: 1rem;
  margin-top: 1rem;
  margin-bottom: 5px;
}
.modal__select {
  border: 1px solid #e0e9fa;
  border-radius: 8px;
  color: #707070;
  width: 400px;
}
.choices-block {
  border-bottom: 2px solid #eee;
}
.modal-body {
  padding: 1rem;
}
.modal-columns {
  display: flex;
}
.modal-column {
  flex: 1;
}
.modal-footer {
  border-top: none;
}
.modal__input {
  width: 400px;
  border-radius: 10px;
  color: #acacac;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
}
.modal__inputs-quantity {
  width: 100%;
}
.modal__select-quantity {
  display: inline-block;
  border: 1px solid #e0e9fa;
  border-radius: 8px;
  color: #707070;
  width: 190px;
}
.modal__input-quantity {
  width: 190px;
  border-radius: 10px;
  color: #acacac;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  display: inline-block;
}
.label_inputs {
  font-weight: 400;
  display: flex;
  margin-top: 20px;
}
.modal__button1 {
  width: 47%;
  background: #f2f2f8;
  border-radius: 8px;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: black;
  margin-top: 20px;
}

.modal__button2 {
  width: 47%;
  background: #e0e9fa;
  border-radius: 8px;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #0366d6;
  margin-right: 28px;
  margin-top: 20px;
}
.add__product {
  width: 96%;
  font-weight: 500;
  font-size: 16px;
  line-height: 128%;
  color: #0366d6;
  display: flex;
  margin-top: 16px;
  margin-left: 20px;
  margin-right: 38px;
  padding: 20px;
  align-items: center;
  border: 2px solid #0366d6;
  border-radius: 8px;
  cursor: pointer;
  justify-content: center;
}
.inputAdd {
  border: 4px dashed #4a504c;
  z-index: 1000;
}
.btn-quantity {
  width: 60px;
  height: 40px;
  background-color: #ffffff;
  border: 1px solid #777777;
  border-radius: 0;
}
.btn-quantity-active {
  width: 60px;
  height: 40px;
  background-color: #e0e9fa;
  color: #0366d6;
  border: 1px solid #777777;
  border-radius: 0;
}
.delete-item {
  float: right;
  background-color: #fff;
  color: #0366d6;
  line-height: 40px;
}
</style>
